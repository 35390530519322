import React, { useState, useCallback } from 'react';
import './App.css';
import { generateCreature, buyCreature } from './api';
import { ClipLoader } from 'react-spinners';
import { Button, TextField, Typography, Container, Box, Grid, Alert } from '@mui/material';

import ReactDOM from 'react-dom/client';
import './index.css'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Define constants
const PAYMENT_AMOUNT = 299; // 1.99 USD in cents

//const API_URL = 'https://creatures.network-telemetrix.com'
const API_URL = process.env.REACT_APP_BACKEND_API_URL
//const API_URL = process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:5000';

function App() {
  // Define states
  const [clientName, setClientName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [creatureDetails, setCreatureDetails] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [creature, setCreature] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  // Function to validate email format
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handle the purchase of the creature
  const handleBuyCreature = useCallback(
    async (creatureData) => {
      if (!creatureData) {
        setMessage('Please generate a creature first.');
        return;
      }
      setLoading(true);
      try {
        const data = await buyCreature(clientName, clientEmail, birthDate, creatureData);
        setMessage(data.message);
        setQrCodeUrl(data.creature.QR_code_url);
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data.error);
        } else {
          setMessage('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    },
    [clientName, clientEmail, birthDate]
  );

  // Handle the generation of the creature
  const handleGenerateCreature = async () => {
    if (!clientName) {
      setMessage('Se requiere el nombre.');
      return;
    }

    if (!birthDate) {
      setMessage('Se requiere la fecha de nacimiento.');
      return;
    }

    if (!clientEmail) {
      setMessage('Se requiere el correo electrónico.');
      return;
    }
    if (!isValidEmail(clientEmail)) {
      setMessage('El correo electrónico no es válido.');
      return;
    }

    if (!creatureDetails) {
      setMessage('Se requieren los detalles de la criatura.');
      return;
    }

    setCreature(null);
    setMessage('');
    setQrCodeUrl('');
    setLoading(true);

    try {
      const data = await generateCreature(clientName, birthDate, clientEmail, creatureDetails);
      setCreature(data);
      setMessage('');

      // Call handleBuyCreature with the generated creature data
      await handleBuyCreature(data);
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.error);
      } else {
        setMessage('An unexpected error occurred while generating the creature.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (    
    <Container 
      //maxWidth="lg" 
      maxWidth={false} 
      disableGutters  // Remove the container's automatic margins
      sx={{ 
        backgroundColor: '#0D3F4D', 
        display: 'flex', // Use Flexbox for alignment
        flexDirection: 'column', // Content direction
        justifyContent: 'center', // Center content vertically
        alignItems: 'center', // Center content horizontally
        color: '#fff', 
        py: 2 
      }}     
    >
      <Box sx={{ textAlign: 'center', mt: 2, mb: 0.8 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            lineHeight: '1.6',
            color: '#FFFFFF',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '19px',
            marginLeft: '10px',  
            marginRight: '10px',
            letterSpacing: '0.06em',
          }}
        >
          Criaturas Mágicas para mis hijos IAN, ABDEL y mi hija BRIGGITTE
        </Typography>
        
        <Typography 
          variant="body2"  // Variant for smaller text
          sx={{
            color: '#B0BEC5',  // Light gray color for text
            fontFamily: 'Poppins, sans-serif',
            fontSize: '12.5px',  // Smaller font size
            mb: 1.5,  // Bottom margin to separate from the next content
            marginLeft: '10px',  
            marginRight: '10px'
          }}
        >
          Crea tu criatura mágica y mitológica basado en tu fecha de nacimiento y tu nombre. Ingresa abajo.
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={11} sm={7} md={4}>
          <TextField
            label="Full Name"
            variant="filled"
            fullWidth
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            sx={{ mb: 0, backgroundColor: '#ffffff', borderRadius: '5px' }}
          />
        </Grid>
        <Grid item xs={11} sm={7} md={4}>
          <TextField
            label="Your Birth Date"
            type="date"
            variant="filled"
            fullWidth
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 0, backgroundColor: '#ffffff', borderRadius: '5px' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={11} sm={7} md={8}>
          <TextField
            label="Your Email"
            variant="filled"
            fullWidth
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
            sx={{ mb: 1, mt: 2, backgroundColor: '#ffffff', borderRadius: '5px' }}
          /> 

          <TextField
            label="Define Your Creature"
            variant="filled"
            fullWidth
            value={creatureDetails}
            onChange={(e) => setCreatureDetails(e.target.value)}
            sx={{ mb: 4, mt: 1, backgroundColor: '#ffffff', borderRadius: '5px' }}
          /> 
        </Grid>       
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateCreature}
        disabled={loading}
        sx={{
          mb: 3,
          backgroundColor: '#00C3F3',
          color: '#fff',
          '&:hover': { backgroundColor: '#0099cc' }, // Add hover effect
        }}
      >
        {loading ? 'Generating...' : 'Generate Creature'}
      </Button>

      {loading && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <ClipLoader color="#FF4245" loading={loading} size={100} /> 
          <Typography sx={{ color: '#fff', mt: 2 }}>Creando tu Criatura con IA...</Typography>
        </Box>
      )}

      {creature && (
        <Box 
          sx={{ 
            mt: 4, 
            textAlign: 'center',       
            marginLeft: '10px',  
            marginRight: '10px',
            color: '#fff' 
          }}
        >
          <Typography variant="h5" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            {creature.name}
          </Typography>
          <img
            //src={creature.image_url}
            src={`${API_URL}/api/static/creatures/${creature.image_url}`}  // Usa el endpoint de la API
            alt="Creature"
            style={{ 
              width: '100%', 
              height: 'auto', 
              maxWidth: '960px', 
              borderRadius: '8px', 
              marginTop: '10px',
              marginLeft: '0px',  
              marginRight: '0px'
            }}
          />
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: '1px solid #ccc',
              borderRadius: '8px',
              backgroundColor: '#333',
              textAlign: 'center',
              justifyContent: 'center',
              maxWidth: '960px',
              margin: '10px 0px'
            }}
          >
            <Typography variant="body2" 
              sx={{ 
                mt: 1, 
                textAlign: 'justify',
                fontFamily: 'Mulish, sans-serif', 
                fontSize: '14px', 
                color: '#ddd' 
              }}>
              {creature.description}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>Creature Number:</strong> {creature.unique_number}
          </Typography>
        </Box>
      )}

      
      {creature && !loading && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: '#fff' }}>Si puedes contribuir Genial!!</Typography>
          <Typography variant="body1" sx={{ mb: 2, color: '#ddd' }}>
            Price: ${PAYMENT_AMOUNT / 100} USD
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open('https://www.paypal.com/ncp/payment/V7G7GHPQU9LCC', '_blank')}
            sx={{ 
              mb: 0, 
              backgroundColor: '#CC24DE',
              '&:hover': { backgroundColor: '#FF5700' } 
            }} // Add hover effect
          >
            Donate
          </Button>
        </Box>
      )}
      
      {/*
      {qrCodeUrl && (
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2, color: '#fff' }}>Your QR Code</Typography>
          <img
            src={`${API_URL}${qrCodeUrl}`}
            alt="Creature QR Code"
            style={{ width: '100%', maxWidth: '200px', margin: '10px 0' }}
          />
          <Typography sx={{ color: '#ddd' }}>{`${API_URL}${qrCodeUrl}`}</Typography>
        </Box>
      )}
      */}      

      {message && (
        <Box sx={{ mt: 4 }}>
          <Alert severity="info">{message}</Alert>
        </Box>
      )}
    </Container>
  );
}
export default App;
